exports.components = {
  "component---posts-1-20-circleci-project-mdx": () => import("./../../../posts/1.20/circleci-project.mdx" /* webpackChunkName: "component---posts-1-20-circleci-project-mdx" */),
  "component---posts-1-20-how-to-expect-an-exception-with-phpunit-mdx": () => import("./../../../posts/1.20/how-to-expect-an-exception-with-phpunit.mdx" /* webpackChunkName: "component---posts-1-20-how-to-expect-an-exception-with-phpunit-mdx" */),
  "component---posts-1-20-jest-testing-jest-testing-mdx": () => import("./../../../posts/1.20/jest-testing/jest-testing.mdx" /* webpackChunkName: "component---posts-1-20-jest-testing-jest-testing-mdx" */),
  "component---posts-10-20-google-ads-audience-post-mdx": () => import("./../../../posts/10.20/google-ads-audience/post.mdx" /* webpackChunkName: "component---posts-10-20-google-ads-audience-post-mdx" */),
  "component---posts-11-19-git-remove-all-local-branches-mdx": () => import("./../../../posts/11.19/git-remove-all-local-branches.mdx" /* webpackChunkName: "component---posts-11-19-git-remove-all-local-branches-mdx" */),
  "component---posts-11-19-use-redux-with-hooks-mdx": () => import("./../../../posts/11.19/use-redux-with-hooks.mdx" /* webpackChunkName: "component---posts-11-19-use-redux-with-hooks-mdx" */),
  "component---posts-11-22-puppeteer-to-video-main-mdx": () => import("./../../../posts/11.22/puppeteer-to-video/main.mdx" /* webpackChunkName: "component---posts-11-22-puppeteer-to-video-main-mdx" */),
  "component---posts-12-19-circleci-circleci-mastery-mdx": () => import("./../../../posts/12.19/circleci/circleci-mastery.mdx" /* webpackChunkName: "component---posts-12-19-circleci-circleci-mastery-mdx" */),
  "component---posts-12-19-clean-node-modeuls-clean-all-node-modules-on-computer-mdx": () => import("./../../../posts/12.19/clean-node-modeuls/clean-all-node-modules-on-computer.mdx" /* webpackChunkName: "component---posts-12-19-clean-node-modeuls-clean-all-node-modules-on-computer-mdx" */),
  "component---posts-12-19-vscode-language-specific-configs-mdx": () => import("./../../../posts/12.19/vscode-language-specific-configs.mdx" /* webpackChunkName: "component---posts-12-19-vscode-language-specific-configs-mdx" */),
  "component---posts-12-20-leetcode-solutions-post-mdx": () => import("./../../../posts/12.20/leetcode-solutions/post.mdx" /* webpackChunkName: "component---posts-12-20-leetcode-solutions-post-mdx" */),
  "component---posts-2-20-aws-solutions-architect-associate-aws-solutions-architect-associate-mdx": () => import("./../../../posts/2.20/aws-solutions-architect-associate/aws-solutions-architect-associate.mdx" /* webpackChunkName: "component---posts-2-20-aws-solutions-architect-associate-aws-solutions-architect-associate-mdx" */),
  "component---posts-2-20-contentful-migration-contentful-migration-mdx": () => import("./../../../posts/2.20/contentful-migration/contentful-migration.mdx" /* webpackChunkName: "component---posts-2-20-contentful-migration-contentful-migration-mdx" */),
  "component---posts-2021-05-python-notes-main-mdx": () => import("./../../../posts/2021/05/python-notes/main.mdx" /* webpackChunkName: "component---posts-2021-05-python-notes-main-mdx" */),
  "component---posts-2023-best-practices-for-performance-optimization-in-web-applications-main-mdx": () => import("./../../../posts/2023/best-practices-for-performance-optimization-in-web-applications/main.mdx" /* webpackChunkName: "component---posts-2023-best-practices-for-performance-optimization-in-web-applications-main-mdx" */),
  "component---posts-2023-is-prime-main-mdx": () => import("./../../../posts/2023/is-prime/main.mdx" /* webpackChunkName: "component---posts-2023-is-prime-main-mdx" */),
  "component---posts-6-20-two-ropes-brain-two-ropes-brain-mdx": () => import("./../../../posts/6.20/two-ropes-brain/two-ropes-brain.mdx" /* webpackChunkName: "component---posts-6-20-two-ropes-brain-two-ropes-brain-mdx" */),
  "component---posts-7-20-python-custom-sort-function-post-mdx": () => import("./../../../posts/7.20/python-custom-sort-function/post.mdx" /* webpackChunkName: "component---posts-7-20-python-custom-sort-function-post-mdx" */),
  "component---posts-8-20-python-loops-python-loops-mdx": () => import("./../../../posts/8.20/python-loops/python-loops.mdx" /* webpackChunkName: "component---posts-8-20-python-loops-python-loops-mdx" */),
  "component---posts-8-22-api-db-coupling-main-mdx": () => import("./../../../posts/8.22/api-db-coupling/main.mdx" /* webpackChunkName: "component---posts-8-22-api-db-coupling-main-mdx" */),
  "component---posts-9-20-automate-github-gatsby-post-mdx": () => import("./../../../posts/9.20/automate-github-gatsby/post.mdx" /* webpackChunkName: "component---posts-9-20-automate-github-gatsby-post-mdx" */),
  "component---posts-9-22-system-design-template-main-mdx": () => import("./../../../posts/9.22/system-design-template/main.mdx" /* webpackChunkName: "component---posts-9-22-system-design-template-main-mdx" */),
  "component---posts-coding-interview-tips-main-mdx": () => import("./../../../posts/coding-interview-tips/main.mdx" /* webpackChunkName: "component---posts-coding-interview-tips-main-mdx" */),
  "component---posts-manage-up-main-mdx": () => import("./../../../posts/manage-up/main.mdx" /* webpackChunkName: "component---posts-manage-up-main-mdx" */),
  "component---posts-old-wordpress-airpods-with-alfred-workflow-shortcut-mdx": () => import("./../../../posts/old-wordpress/airpods-with-alfred-workflow-shortcut.mdx" /* webpackChunkName: "component---posts-old-wordpress-airpods-with-alfred-workflow-shortcut-mdx" */),
  "component---posts-old-wordpress-alfredapp-quick-productivity-hack-mdx": () => import("./../../../posts/old-wordpress/alfredapp-quick-productivity-hack.mdx" /* webpackChunkName: "component---posts-old-wordpress-alfredapp-quick-productivity-hack-mdx" */),
  "component---posts-old-wordpress-balanced-brackets-problem-hackerrank-mdx": () => import("./../../../posts/old-wordpress/balanced-brackets-problem-hackerrank.mdx" /* webpackChunkName: "component---posts-old-wordpress-balanced-brackets-problem-hackerrank-mdx" */),
  "component---posts-old-wordpress-be-a-master-of-package-versioning-mdx": () => import("./../../../posts/old-wordpress/be-a-master-of-package-versioning.mdx" /* webpackChunkName: "component---posts-old-wordpress-be-a-master-of-package-versioning-mdx" */),
  "component---posts-old-wordpress-become-functional-basic-functions-mdx": () => import("./../../../posts/old-wordpress/become-functional-basic-functions.mdx" /* webpackChunkName: "component---posts-old-wordpress-become-functional-basic-functions-mdx" */),
  "component---posts-old-wordpress-black-box-sms-integrations-mdx": () => import("./../../../posts/old-wordpress/black-box-sms-integrations.mdx" /* webpackChunkName: "component---posts-old-wordpress-black-box-sms-integrations-mdx" */),
  "component---posts-old-wordpress-bloompy-making-travel-social-mdx": () => import("./../../../posts/old-wordpress/bloompy-making-travel-social.mdx" /* webpackChunkName: "component---posts-old-wordpress-bloompy-making-travel-social-mdx" */),
  "component---posts-old-wordpress-convert-2-0-review-frank-kern-mdx": () => import("./../../../posts/old-wordpress/convert-2-0-review-frank-kern.mdx" /* webpackChunkName: "component---posts-old-wordpress-convert-2-0-review-frank-kern-mdx" */),
  "component---posts-old-wordpress-counting-valleys-hackerrank-challenge-mdx": () => import("./../../../posts/old-wordpress/counting-valleys-hackerrank-challenge.mdx" /* webpackChunkName: "component---posts-old-wordpress-counting-valleys-hackerrank-challenge-mdx" */),
  "component---posts-old-wordpress-courier-warehouse-integration-mdx": () => import("./../../../posts/old-wordpress/courier-warehouse-integration.mdx" /* webpackChunkName: "component---posts-old-wordpress-courier-warehouse-integration-mdx" */),
  "component---posts-old-wordpress-create-a-connect-airpods-cli-command-mdx": () => import("./../../../posts/old-wordpress/create-a-connect-airpods-cli-command.mdx" /* webpackChunkName: "component---posts-old-wordpress-create-a-connect-airpods-cli-command-mdx" */),
  "component---posts-old-wordpress-current-react-base-stack-mdx": () => import("./../../../posts/old-wordpress/current-react-base-stack.mdx" /* webpackChunkName: "component---posts-old-wordpress-current-react-base-stack-mdx" */),
  "component---posts-old-wordpress-cyclic-rotation-coditility-mdx": () => import("./../../../posts/old-wordpress/cyclic-rotation-coditility.mdx" /* webpackChunkName: "component---posts-old-wordpress-cyclic-rotation-coditility-mdx" */),
  "component---posts-old-wordpress-dating-site-for-beautiful-people-mdx": () => import("./../../../posts/old-wordpress/dating-site-for-beautiful-people.mdx" /* webpackChunkName: "component---posts-old-wordpress-dating-site-for-beautiful-people-mdx" */),
  "component---posts-old-wordpress-docker-removing-unused-images-and-container-mdx": () => import("./../../../posts/old-wordpress/docker-removing-unused-images-and-container.mdx" /* webpackChunkName: "component---posts-old-wordpress-docker-removing-unused-images-and-container-mdx" */),
  "component---posts-old-wordpress-facebook-audiences-on-huge-scale-mdx": () => import("./../../../posts/old-wordpress/facebook-audiences-on-huge-scale.mdx" /* webpackChunkName: "component---posts-old-wordpress-facebook-audiences-on-huge-scale-mdx" */),
  "component---posts-old-wordpress-facebook-custom-audience-api-creation-mdx": () => import("./../../../posts/old-wordpress/facebook-custom-audience-api-creation.mdx" /* webpackChunkName: "component---posts-old-wordpress-facebook-custom-audience-api-creation-mdx" */),
  "component---posts-old-wordpress-fast-development-is-really-fast-mdx": () => import("./../../../posts/old-wordpress/fast-development-is-really-fast.mdx" /* webpackChunkName: "component---posts-old-wordpress-fast-development-is-really-fast-mdx" */),
  "component---posts-old-wordpress-fullstack-developers-vs-specific-developer-mdx": () => import("./../../../posts/old-wordpress/fullstack-developers-vs-specific-developer.mdx" /* webpackChunkName: "component---posts-old-wordpress-fullstack-developers-vs-specific-developer-mdx" */),
  "component---posts-old-wordpress-git-101-tips-and-best-practices-mdx": () => import("./../../../posts/old-wordpress/git-101-tips-and-best-practices.mdx" /* webpackChunkName: "component---posts-old-wordpress-git-101-tips-and-best-practices-mdx" */),
  "component---posts-old-wordpress-hackerrank-divisible-sum-pairs-mdx": () => import("./../../../posts/old-wordpress/hackerrank-divisible-sum-pairs.mdx" /* webpackChunkName: "component---posts-old-wordpress-hackerrank-divisible-sum-pairs-mdx" */),
  "component---posts-old-wordpress-hackerrank-kangaroo-exercise-mdx": () => import("./../../../posts/old-wordpress/hackerrank-kangaroo-exercise.mdx" /* webpackChunkName: "component---posts-old-wordpress-hackerrank-kangaroo-exercise-mdx" */),
  "component---posts-old-wordpress-hackerrank-queues-a-tale-of-two-stacks-mdx": () => import("./../../../posts/old-wordpress/hackerrank-queues-a-tale-of-two-stacks.mdx" /* webpackChunkName: "component---posts-old-wordpress-hackerrank-queues-a-tale-of-two-stacks-mdx" */),
  "component---posts-old-wordpress-hackerrank-sock-merchant-mdx": () => import("./../../../posts/old-wordpress/hackerrank-sock-merchant.mdx" /* webpackChunkName: "component---posts-old-wordpress-hackerrank-sock-merchant-mdx" */),
  "component---posts-old-wordpress-how-to-find-the-median-algorithms-mdx": () => import("./../../../posts/old-wordpress/how-to-find-the-median-algorithms.mdx" /* webpackChunkName: "component---posts-old-wordpress-how-to-find-the-median-algorithms-mdx" */),
  "component---posts-old-wordpress-how-to-use-javascript-generators-mdx": () => import("./../../../posts/old-wordpress/how-to-use-javascript-generators.mdx" /* webpackChunkName: "component---posts-old-wordpress-how-to-use-javascript-generators-mdx" */),
  "component---posts-old-wordpress-how-we-secured-our-url-forwarder-with-jwt-mdx": () => import("./../../../posts/old-wordpress/how-we-secured-our-url-forwarder-with-jwt.mdx" /* webpackChunkName: "component---posts-old-wordpress-how-we-secured-our-url-forwarder-with-jwt-mdx" */),
  "component---posts-old-wordpress-ironsource-search-application-mdx": () => import("./../../../posts/old-wordpress/ironsource-search-application.mdx" /* webpackChunkName: "component---posts-old-wordpress-ironsource-search-application-mdx" */),
  "component---posts-old-wordpress-javascript-asynchronous-testing-mdx": () => import("./../../../posts/old-wordpress/javascript-asynchronous-testing.mdx" /* webpackChunkName: "component---posts-old-wordpress-javascript-asynchronous-testing-mdx" */),
  "component---posts-old-wordpress-javascript-console-life-saving-tricks-mdx": () => import("./../../../posts/old-wordpress/javascript-console-life-saving-tricks.mdx" /* webpackChunkName: "component---posts-old-wordpress-javascript-console-life-saving-tricks-mdx" */),
  "component---posts-old-wordpress-javascript-es-6-3-dots-101-mdx": () => import("./../../../posts/old-wordpress/javascript-es6-3-dots-101.mdx" /* webpackChunkName: "component---posts-old-wordpress-javascript-es-6-3-dots-101-mdx" */),
  "component---posts-old-wordpress-jumping-on-the-clouds-hackerrank-challenge-mdx": () => import("./../../../posts/old-wordpress/jumping-on-the-clouds-hackerrank-challenge.mdx" /* webpackChunkName: "component---posts-old-wordpress-jumping-on-the-clouds-hackerrank-challenge-mdx" */),
  "component---posts-old-wordpress-jwt-awesome-killer-use-cases-mdx": () => import("./../../../posts/old-wordpress/jwt-awesome-killer-use-cases.mdx" /* webpackChunkName: "component---posts-old-wordpress-jwt-awesome-killer-use-cases-mdx" */),
  "component---posts-old-wordpress-laravel-messaging-package-mdx": () => import("./../../../posts/old-wordpress/laravel-messaging-package.mdx" /* webpackChunkName: "component---posts-old-wordpress-laravel-messaging-package-mdx" */),
  "component---posts-old-wordpress-legacy-php-to-top-notch-code-mdx": () => import("./../../../posts/old-wordpress/legacy-php-to-top-notch-code.mdx" /* webpackChunkName: "component---posts-old-wordpress-legacy-php-to-top-notch-code-mdx" */),
  "component---posts-old-wordpress-my-2018-review-mdx": () => import("./../../../posts/old-wordpress/my-2018-review.mdx" /* webpackChunkName: "component---posts-old-wordpress-my-2018-review-mdx" */),
  "component---posts-old-wordpress-my-journey-to-learning-docker-mdx": () => import("./../../../posts/old-wordpress/my-journey-to-learning-docker.mdx" /* webpackChunkName: "component---posts-old-wordpress-my-journey-to-learning-docker-mdx" */),
  "component---posts-old-wordpress-nodejs-trade-rates-service-mdx": () => import("./../../../posts/old-wordpress/nodejs-trade-rates-service.mdx" /* webpackChunkName: "component---posts-old-wordpress-nodejs-trade-rates-service-mdx" */),
  "component---posts-old-wordpress-podium-project-journey-mdx": () => import("./../../../posts/old-wordpress/podium-project-journey.mdx" /* webpackChunkName: "component---posts-old-wordpress-podium-project-journey-mdx" */),
  "component---posts-old-wordpress-python-args-and-kwargs-explained-mdx": () => import("./../../../posts/old-wordpress/python-args-and-kwargs-explained.mdx" /* webpackChunkName: "component---posts-old-wordpress-python-args-and-kwargs-explained-mdx" */),
  "component---posts-old-wordpress-python-testing-for-the-lazy-dev-mdx": () => import("./../../../posts/old-wordpress/python-testing-for-the-lazy-dev.mdx" /* webpackChunkName: "component---posts-old-wordpress-python-testing-for-the-lazy-dev-mdx" */),
  "component---posts-old-wordpress-quickstart-mongodb-with-docker-mdx": () => import("./../../../posts/old-wordpress/quickstart-mongodb-with-docker.mdx" /* webpackChunkName: "component---posts-old-wordpress-quickstart-mongodb-with-docker-mdx" */),
  "component---posts-old-wordpress-react-imported-svg-as-css-background-mdx": () => import("./../../../posts/old-wordpress/react-imported-svg-as-css-background.mdx" /* webpackChunkName: "component---posts-old-wordpress-react-imported-svg-as-css-background-mdx" */),
  "component---posts-old-wordpress-react-testing-library-internal-elements-mdx": () => import("./../../../posts/old-wordpress/react-testing-library-internal-elements.mdx" /* webpackChunkName: "component---posts-old-wordpress-react-testing-library-internal-elements-mdx" */),
  "component---posts-old-wordpress-rework-book-review-handpicked-notes-mdx": () => import("./../../../posts/old-wordpress/rework-book-review-handpicked-notes.mdx" /* webpackChunkName: "component---posts-old-wordpress-rework-book-review-handpicked-notes-mdx" */),
  "component---posts-old-wordpress-rewrite-from-backbone-to-react-best-practices-mdx": () => import("./../../../posts/old-wordpress/rewrite-from-backbone-to-react-best-practices.mdx" /* webpackChunkName: "component---posts-old-wordpress-rewrite-from-backbone-to-react-best-practices-mdx" */),
  "component---posts-old-wordpress-robinhood-bingo-mdx": () => import("./../../../posts/old-wordpress/robinhood-bingo.mdx" /* webpackChunkName: "component---posts-old-wordpress-robinhood-bingo-mdx" */),
  "component---posts-old-wordpress-save-time-with-these-5-communication-work-tips-mdx": () => import("./../../../posts/old-wordpress/save-time-with-these-5-communication-work-tips.mdx" /* webpackChunkName: "component---posts-old-wordpress-save-time-with-these-5-communication-work-tips-mdx" */),
  "component---posts-old-wordpress-short-arrow-function-are-coming-to-php-mdx": () => import("./../../../posts/old-wordpress/short-arrow-function-are-coming-to-php.mdx" /* webpackChunkName: "component---posts-old-wordpress-short-arrow-function-are-coming-to-php-mdx" */),
  "component---posts-old-wordpress-test-mock-es-6-import-mdx": () => import("./../../../posts/old-wordpress/test-mock-es6-import.mdx" /* webpackChunkName: "component---posts-old-wordpress-test-mock-es-6-import-mdx" */),
  "component---posts-old-wordpress-the-boron-letters-review-gary-halbert-mdx": () => import("./../../../posts/old-wordpress/the-boron-letters-review-gary-halbert.mdx" /* webpackChunkName: "component---posts-old-wordpress-the-boron-letters-review-gary-halbert-mdx" */),
  "component---posts-old-wordpress-the-never-ending-journey-of-a-developer-mdx": () => import("./../../../posts/old-wordpress/the-never-ending-journey-of-a-developer.mdx" /* webpackChunkName: "component---posts-old-wordpress-the-never-ending-journey-of-a-developer-mdx" */),
  "component---posts-old-wordpress-tribes-by-seth-godin-review-mdx": () => import("./../../../posts/old-wordpress/tribes-by-seth-godin-review.mdx" /* webpackChunkName: "component---posts-old-wordpress-tribes-by-seth-godin-review-mdx" */),
  "component---posts-old-wordpress-video-api-service-mdx": () => import("./../../../posts/old-wordpress/video-api-service.mdx" /* webpackChunkName: "component---posts-old-wordpress-video-api-service-mdx" */),
  "component---posts-old-wordpress-video-api-service-on-serverless-engine-mdx": () => import("./../../../posts/old-wordpress/video-api-service-on-serverless-engine.mdx" /* webpackChunkName: "component---posts-old-wordpress-video-api-service-on-serverless-engine-mdx" */),
  "component---posts-old-wordpress-what-are-javascript-callbacks-for-a-newbie-mdx": () => import("./../../../posts/old-wordpress/what-are-javascript-callbacks-for-a-newbie.mdx" /* webpackChunkName: "component---posts-old-wordpress-what-are-javascript-callbacks-for-a-newbie-mdx" */),
  "component---posts-old-wordpress-what-the-hack-is-jwt-mdx": () => import("./../../../posts/old-wordpress/what-the-hack-is-jwt.mdx" /* webpackChunkName: "component---posts-old-wordpress-what-the-hack-is-jwt-mdx" */),
  "component---posts-old-wordpress-workflow-builder-mdx": () => import("./../../../posts/old-wordpress/workflow-builder.mdx" /* webpackChunkName: "component---posts-old-wordpress-workflow-builder-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-grid-js": () => import("./../../../src/pages/grid.js" /* webpackChunkName: "component---src-pages-grid-js" */),
  "component---src-pages-upwork-js": () => import("./../../../src/pages/upwork.js" /* webpackChunkName: "component---src-pages-upwork-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-home-page-template-js": () => import("./../../../src/templates/homePageTemplate.js" /* webpackChunkName: "component---src-templates-home-page-template-js" */),
  "component---src-templates-my-work-template-js": () => import("./../../../src/templates/myWorkTemplate.js" /* webpackChunkName: "component---src-templates-my-work-template-js" */),
  "component---src-templates-posts-list-template-js": () => import("./../../../src/templates/postsListTemplate.js" /* webpackChunkName: "component---src-templates-posts-list-template-js" */)
}

